import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledDiv = styled.div`
	color: ${CSSVars.gray900};
	max-width: 1420px;
	margin: 0 auto;
	
	&:not(.static) {
		margin: -130px auto 0;

		@media screen and (min-width: ${CSSVars.breakpointLG}) {
			margin: -200px auto 0;
		}
	}

	h2 {
		margin: 50px auto 0;
		font-size: ${CSSVars.fontSize2XL};
		font-weight: 600;
		text-align: center;

		@media screen and (min-width: ${CSSVars.breakpointMD}) {
			font-size: ${CSSVars.fontSize4XL};
		}

		span {
			color: ${CSSVars.primary500};
		}
	}

	.video-container {
		margin: 0 auto;
		display: flex;
		justify-content: center;

		iframe {
			border-radius: 10px;
			max-height: 100%;
			/* height: 230px;

			@media screen and (min-width: ${CSSVars.breakpointSM}) {
				height: 380px;
			} */
			@media screen and (min-width: ${CSSVars.breakpointMD}) {
				height: 523px;
			}
		}
	}
`;
