import { GetStaticPropsContext } from 'next';
import Script from 'next/script';
import { Faq } from '../../../components/faq/faq.comp';
import { Layout } from '../../../components/layout/layout.comp';
import { SupportedPlatforms } from '../../../components/supportedPlatforms/supportedPlatforms.comp';
import { Testimonials } from '../../../components/testimonials/testimonials.comp';
import { TrustedBy } from '../../../components/trustedBy/trustedBy.comp';
import { WhyUse } from '../../../components/whyUse/whyUse.comp';
import { WhyUseWidget } from '../../../components/whyUseWidget/whyUseWidget.comp';
import { WidgetFeatures } from '../../../components/widgetFeatures/widgetFeatures.comp';
import { WidgetHero } from '../../../components/widgetHero/widgetHero.comp';
import { WidgetVideoGuide } from '../../../components/widgetVideoGuide/widgetVideoGuide.comp';
import { emptyApp } from '../../../lib/empty';
import { getDataFromDB } from '../../../lib/mongodb';
import { IPlugin } from '../../../types';
import { useEffect } from 'react';
import { trackEvent } from '../../../lib/track';
import { WidgetSocialProof } from '../../../components/widgetSocialProof/widgetSocialProof.comp';

export default function Widget({ app }: { app: IPlugin; }) {
	useEffect(() => {
		trackEvent(`Enter App Page`, {
			serviceName: app.serviceName,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!app.slug) {
		return <></>;
	}

	return (
		<Layout
			showHeader={true}
			showFooter={true}
			title={app.meta.title}
			description={app.meta.description}
			image={app.meta.imgUrl}
			serviceName={app.serviceName}
			url={`https://www.commoninja.com/widgets/${app.slug}`}
		>
			<WidgetScripts app={app} />
			<WidgetHero widget={app} />
			<WidgetSocialProof />
			<WhyUseWidget widget={app} />
			<WidgetFeatures widget={app} />
			<WidgetVideoGuide staticMode widget={app} />
			<TrustedBy />
			<WhyUse />
			<Testimonials mode="light" />
			<Faq faqs={app.faq.map((faq) => faq)} />
			<SupportedPlatforms />
		</Layout>
	);
}

const WidgetScripts = ({ app }: { app: IPlugin; }) => {
	let ratingValue: number = 4.8;
	if (app.slug === 'brackets') {
		ratingValue = 4.9;
	}
	if (
		app.slug === 'messenger-button' ||
		app.slug === 'telegram-button' ||
		app.slug === 'whatsapp-button'
	) {
		ratingValue = 4.7;
	}

	function getRandomInt(min: number, max: number) {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}

	function renderRating() {
		if (app.status === 'coming-soon') {
			return '';
		}

		return `"aggregateRating": {
      "@type": "AggregateRating",
      "worstRating": "1",
      "bestRating": "5",
      "ratingCount": "${getRandomInt(1, 120) + 150}",
      "ratingValue": "${ratingValue}"
    }`;
	}
	return (
		<>
			<Script id="zendask-widget" type="text/javascript">
				{`if (typeof zE !== 'undefined') {
                zE('webWidget', 'helpCenter:setSuggestions', { search: '${app.name}' });
            }`}
			</Script>
			<Script id="product-scheme" type="application/ld+json">{`
          {
              "@context": "https://schema.org/", 
              "@type": "Product", 
              "name": "${app.name}",
              "image": "${app.hero}",
              "description": "${app.teaser.replace(/"/g, `'`)}"${app.status !== 'coming-soon' ? ',' : ''}
              ${renderRating()}
          }
            `}</Script>
		</>
	);
};

export async function getStaticPaths() {
	try {
		const apps = await getDataFromDB<IPlugin>(
			'apps',
			{
				status: { $in: ['published', 'coming-soon'] },
			},
			{ slug: 1 }
		);
		const paths = apps.map((app: IPlugin) => ({
			params: { slug: app.slug },
		}));

		return { paths, fallback: false };
	} catch (e) {
		console.error(e);
		return { paths: [], fallback: false };
	}
}

export async function getStaticProps({
	params = { slug: '' },
}: GetStaticPropsContext<{ slug: string; }>) {
	const { slug } = params;
	const apps = await getDataFromDB<IPlugin>('apps', {
		slug,
	});
	return { props: { app: apps[0] || emptyApp() } };
}
