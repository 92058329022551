import React from "react";
import { StyledDiv } from "./widgetVideoGuide.styles";
import { IPlatform, IPlugin } from "../../types";
import { Section } from "../section/section.comp";
import ReactPlayer from "react-player";

export const WidgetVideoGuide = ({ widget, videoUrl, staticMode = false }: { widget: IPlugin, platform?: IPlatform, videoUrl?: string; staticMode?: boolean; }) => {
  if (!widget.videoUrl && !videoUrl) {
    return <></>;
  };

  return (
    <Section>
      <StyledDiv className={`${staticMode ? 'static' : ''}`}>
        <div className="video-container">
          <ReactPlayer
            controls={true}
            width={"100%"}
            height={"523px"}
            style={{ maxWidth: "930px", borderRadius: "10px" }}
            url={videoUrl || widget.videoUrl}
          />
        </div>
      </StyledDiv>
    </Section>
  );
};
